import React, { useState, useEffect } from "react";
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import BreadcrumbPageEnquiry from "@Components/Home/BreadcrumbPage/BreadcrumbPageEnquiry";
import { useStaticQuery, graphql } from "gatsby";
import ContactForm from "@Components/forms/general-enquiry"
import ReactMarkdown from "react-markdown"
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Breadcrumb } from "react-bootstrap";
import LogoImgD from "./../../images/logo-d.svg";
import LogoImgT from "./../../images/logo-t.svg";
import LogoImgM from "./../../images/logo-m.svg";
import { Link } from "@StarberryUtils";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";

import { inViewOptions } from '../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { getUser } from "@starberry/myaccount-website-utils"

import {ImageModule} from "@Components/common/Image_Module";


const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const GeneralEquiry = (props) => {

  const userData = getUser();

  useEffect(() => {
    window.scrollTo(0, 0);
    setName(sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : userData?.name)
    setEmail(sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : userData?.email)
    setPhone(sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : userData?.mobile)
    setmessage(sessionStorage.getItem('message') != null ? sessionStorage.getItem('message') : '')
  }, []);
  const [gobackPage, setgobackPage ] = useState(false)
  const [step, setStep] = useState(0)
  const next = event => {
    if (step === 0) {
      if (!name) {
        if (!name) {
          setNameError(true)
        }
      }
      else {
        setStep(step + 1)
        
      }
    }
    if (step === 1) {
      if (!email || !phone || email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null || phone.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        if (!email) {
          setEmailError(true)
        }
        if (email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValEmailError(true)
        }
        if (!phone) {
          setPhoneError(true)
        }
        if (phone.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValPhoneError(true)
        }
      }
      else {
        
        setStep(step + 1)
        
      }
    }  
    if (step === 2) {
      setStep(step + 1)
    }
  }
  const previous = event => {
    setStep(step-1)
  }
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [valemailError, setValEmailError] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [valphoneError, setValPhoneError] = useState(false)
    const [message, setmessage] = useState(false)
    
  const handlechange = e => {
    // remove initial empty spaces
    sessionStorage.setItem(e.target.name, e.target.value)
    e.target.value = e.target.value.trimStart()
      if (e.target.name === 'name') {
        setName(e.target.value)
        if (e.target.value !== "") {
          setNameError(false)
        }
        else {
          setNameError(true)
        }
      }
      if (e.target.name === 'email') {
        setEmail(e.target.value)
        if (e.target.value === "") {
          setEmailError(true)
        }
        else if (e.target.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValEmailError(true)
          setEmailError(false)
        }
        else {
          setEmailError(false)
          setValEmailError(false)
        }
      }
      if (e.target.name === 'telephone') {
        setPhone(e.target.value)
        if (e.target.value === "") {
          setPhoneError(true)
        }
        else if (e.target.value.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
          setValPhoneError(true)
          setPhoneError(false)
        }
        else {
          setPhoneError(false)
          setValPhoneError(false)
        }
      }
      if (e.target.name === 'message') {
        setmessage(e.target.value)
      }
  
    
  }
  const data = useStaticQuery(graphql`
    query{
      glstrapi {
        globalConfig(publicationState: LIVE) {
          id
            imagetransforms
            ggfx_results {
              id
              content_type
              transforms
              src_import_url
              src_cftle
              field
            }
          Contact_Image {
              url
          }
          Contact_Description
          Contact_Title
        }
    }
    
  }
  `);
  let processedImages1 = JSON.stringify({});
  if (data.glstrapi?.globalConfig?.imagetransforms?.Image_Transforms) {
    processedImages1 = data.glstrapi?.globalConfig?.imagetransforms.Image_Transforms;
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              {/* <div className="header-logo-black form-page-menubar"><Header /></div> */}
              <div className="d-flex bg-fix">
                <div className="max-wisth-fix">
                  <div className="header-logo-black form-page-menubar form-page"><Header gobackPage={gobackPage} previous={previous} step={step} page={'form'} /></div>
                  <div className="general-form reduce-width-form">
                    <motion.div variants={titleVariants}>
                      <h2>{data.glstrapi?.globalConfig?.Contact_Title}</h2>
                      <ReactMarkdown source={data.glstrapi?.globalConfig?.Contact_Description} allowDangerousHtml />
                    </motion.div>

                    <motion.div variants={contentVariants}>
                      <ContactForm 
                      name={name}
                      nameError={nameError}
                      phone={phone}
                      phoneError={phoneError}
                      valphoneError={valphoneError}
                      email={email}
                      emailError={emailError}
                      valemailError={valemailError}
                      handlechange={handlechange}
                      setgobackPage={setgobackPage}
                      previous={previous}
                      next={next}
                      step={step}
                      message={message}
                      Office={'office'}
                       />
                    </motion.div>
                  </div>

                </div>
                <ImageModule renderer="bgImg" ImageSrc={data.glstrapi?.globalConfig?.Contact_Image} attr={{ alt: '', className: 'contact-bg' }} ggfx_results={data.glstrapi?.globalConfig.ggfx_results} imagename="global-config.Contact_Image.bgimg" strapi_id={data.glstrapi?.globalConfig?.id} />

                {/* <ImageTransform imagesources={data.glstrapi?.globalConfig?.Contact_Image?.url} renderer="bgImg"
                  imagename='global-config.Contact_Image.bgimg'
                  attr={{ alt: '', className: 'contact-bg' }}
                  imagetransformresult={processedImages1} id={data.glstrapi?.globalConfig?.id} /> */}
              </div>
              <div className="breadcrumb-other-page"><BreadcrumbPageEnquiry Page={'General Enquiry'} alias={props.slug} /></div>
              <Footer popularSearch={"Common"} />
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  )
}

export default GeneralEquiry