import React, { useRef, useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
import InputField from './elements/input'
import FileField from './elements/file'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha';
import { getUser } from "@starberry/myaccount-website-utils"

import axios from "axios"
import * as qs from "query-string"


function MySimpleForm(props) {

  const userData = getUser();


  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

    const [file, setFile] = useState(""); // storing the uploaded file
// storing the recived file from backend
    const [] = useState({ name: "", path: "" });
    const [] = useState(0); // progess bar
  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "SEND US YOUR CV",
        form_type: "careers",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "careers_user",
        email_temp_admin: "careers_admin",
        email_server_func: "careers",
        event_tracking: "careers",
        page_url: "/careers"
      },
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Enter your name",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        // pattern="^[a-zA-Z0-9]+$",
        patternchk: "^[a-zA-Z0-9()]+(\\s+[a-zA-Z0-9()]+)*$",
        fieldClass: "",
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "Enter your email",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        fieldClass: "",
        labelClass: "content_b-18",
        defaultValue: userData?.email || ``
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "Enter your telephone number",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        fieldClass: "",
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``
      },
      {
        grpmd: "12",
        label: "CV/Resume",
        placeholder: "Click to attach",
        name: "file",
        type: "file",
        element: "file",
        required: true,
        accept:".pdf,.doc",
        fieldClass: "form-control-browsefile custom custom-file form-control attach-file-wrap",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Additional Info",
        placeholder: "Need to share anything else? Write it here...",
        name: "message",
        element: "textarea",
        class: "mb-4",
        rows:"1",
        labelClass: "content_b-18"
      }, 
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },
      {
        type: "hidden",
        element: "input",
        name:"videoname"
      },      
       {
        grpmd: "12",
        name: "Submit Details",
        type:"submit",
        element: "button",
        value: "submit",
        class: "primary",
        labelClass: "content_b-18"
      },
      {
        text: 'By clicking Submit Details, you agree to our <a href="/terms-and-conditions/">Terms &amp; Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>',
        element: "html",
        class: "terms modalterms"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()

  }

  const handleAttachment = event => {
      const fileItem = event.target.files[0]; // accesing file
      var d = document.getElementById('custom-file');
      //alert(d.value);
     $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
     setFile(fileItem); // storing file
     
     $('.form-control-browsefile').addClass("validated");

  };

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;
        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(apiRes => {
          if (apiRes?.attachment[0]?.url) {
            // If image from externall url, use that
            if (apiRes.attachment[0].url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${apiRes.attachment[0].url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
            }
          }

          // lets send mail
          const axiosOptions_email = {
            url: '/.netlify/functions/server/'+fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(() => {
              console.log('mail sent!')
            })
            .catch(err => {

            }
            );
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    // console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {
    if((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['files'] = file;
      //json['videos'] = props.videoblob;
      json['g-recaptcha-response'] = token;

      
      // json['files'] = props.videoblob;
      //setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };


  return (

                
                  
                  <div className="form stbform fix-right">
                    
                    <div ref={myRef} id="scroll_view" />
                  {showerror && <div className="alert-error">
                    <p>{fields[0].error_text}</p>
                  </div>}

                  {showthankyou && <div className="alert-success">
                    <p>{fields[0].success_text}</p>
                  </div>}


                    <Form className="tab-form career-fr" enctype='multipart/form-data' name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit}>
                      <input type="hidden" name="form_name" value={fields[0].formname} />
                      <input type="hidden" name="form_type" value={fields[0].form_type} />
                      <input type="hidden" name="job_title" value={props.jobtitle} />
                      <input type="hidden" name="job_url" value={props.job_url} />
                      <input type="hidden" name="to_email_id" value={process.env.CAREERS_MAIL_TO} />
                      <input type="hidden" name="bot-field" />
                      {fields.map((field, index) => {
                          if ( "input" === field.element ) {
                            return (
                              <InputField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={field.type}
                                // label={field.label}
                                fieldClass={field.fieldClass}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                pattern={field.patternchk}
                                handlechange={handlechange}
                                defaultValue={field?.defaultValue}
                              />
                            );
                          }

                          if ( "file" === field.element ) {
                            return (
                              <FileField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={field.type}
                                fieldClass={field.fieldClass}
                                label={field.label}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                key={`${field.element}~${index}`}
                                accept={field.accept}
                                handlechange={handleAttachment}
                                required={field.required}
                              />
                            );
                          }
                          if ("select" === field.element) {
                            return (
                              <SelectField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                required={field.required}
                                label={field.label}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                values={field.values}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                                componentprops={props}
                              />
                            );
                          }
                          if ("textarea" === field.element) {
                            return (
                              <TextAreaField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                rows={field.rows}
                                // label={field.label}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                              />
                            );
                          }
                          if ("checkbox" === field.element) {
                            return (
                              <CheckboxField
                                name={field.name}
                                ref={field.ref}
                                value={field.value}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.name}~${index}`}
                                handlechange={handlechange}
                                label={field.label}
                              />
                            );
                          }
                          if ("html" === field.element) {
                            return (
                              <HtmlBox
                                text={field.text}
                                fieldClass={field.class}
                                key={`${field.element}~${index}`}
                              />
                            );
                          }
                        if ("captcha" === field.element) {
                            return (
                              <ReCaptchaBox
                                fieldClass={field.class}
                                captRef={field.captchaRef}
                                key={`${field.element}~${index}`}
                                handleonVerify={handleonVerify}
                              />
                            );
                          }

                          if ("button" === field.element) {
                            return (
                              <ButtonField
                              videotrue={props.videotrue}
                                name={field.name}
                                fieldClass={field.class}
                                type={field.type}
                                value={field.value}
                                key={`${field.element}~${index}`}
                              />
                            );
                          }
                        })
                      }
                    
                    </Form>
                  </div>
                
                

  );
}


const MySimpleFormPage = (props) => (
  <MySimpleForm  job_url={props.job_url} to_email_id={props.to_email_id} jobtitle={props.jobtitle}/>
)

export default MySimpleFormPage
