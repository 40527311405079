import React, {useState} from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown} from "react-bootstrap";
import Location from "../../images/location.svg"
import Salary from "../../images/salary.svg"
import "./CareersDetailsBanner.scss";
import { CAREERS_PAGE_URL } from "@Components/common/site/constants";
import ReactMarkdown from "react-markdown"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import AskusaQuestion from "@Components/AskusaQuestion/AskusaQuestion";
import {ImageModule} from "@Components/common/Image_Module";

export const uknumberformat = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}
const CareersDetailsBanner = (props) => {
    const [videoIdmen, setVideoIdmen] = useState('');
    const [showVideomen, setShowVideomen] = useState(false);
    const playVideomen = (Embed_Video_URL) => {  
      var videoid = getVideoId(Embed_Video_URL);
      setShowVideomen(true)
      setVideoIdmen(videoid.id)
      
  }
  const closePlaymen = () => {
    setShowVideomen(false)  
    setVideoIdmen('')
  }
  const trackerVideomen = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  let processedImages = JSON.stringify({});
  if (props.data?.Speak_With?.imagetransforms?.Image_Transforms) {
      processedImages = props.data?.Speak_With?.imagetransforms.Image_Transforms;
    }
    return (
        <React.Fragment>
           <section className="careers-details-banner">
               <Container>
                   <Row>
                       <Col lg={8}>
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                          <div className="back-button-wrap"><Link className="back-link" to={CAREERS_PAGE_URL.alias}><span className="arrow-left-button d-none d-xl-block"><i className="arrow-banner-left"></i></span></Link>
                          <h1>{props.data.Job_Title}</h1>
                          {/* <p className="designation">{props.data.Designation}</p> */}
                          <div className="call"><a > <img src={Location}/> {props.data.Office?.Office_Name} Office, {props.data.Location}</a></div> <div className="call"> <img src={Salary}/> {props.data.Salary}</div>
                          </div> 
                          <div className="button-groups-property-banner d-lg-flex">
                          <a onClick={props.executeScroll} className="btn btn-primary" href="javascript:void(0)">Apply for this Job</a>
                          <AskusaQuestion />
                           </div>
                           <ReactMarkdown source={props.data?.Job_Details} allowDangerousHtml />
                          </ScrollAnimation>
                       </Col>
                       {props.data.Speak_With &&
                       <Col lg={{size:4,offset: 1}} className="d-md-none d-xl-block">
                       {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn"> */}
                         <div className="sticky-blk">
                       <div className="team-vi-blk">
                       {showVideomen && <div onClick={(e) => {closePlaymen(e)}} className="close-iframe-btn"></div> }
                            <div className={showVideomen ? "img-hover-zoom show-video-team" : "img-hover-zoom"}>
                            {showVideomen &&
                        <YouTube
                        video={videoIdmen}
                        autoplay
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        onEnd={e => {closePlaymen(e)}}
                        modestBranding={1}
                        onPlaying={trackerVideomen(props.data.Speak_With?.Name)}

                        />
                        }
                            {/* <picture>
                                <source media="(min-width:992px)" srcSet={props.data.Speak_With.Image?.url} />
                                <source media="(min-width:768px)" srcSet={props.data.Speak_With.Image?.url} />
                                <img src={props.data.Speak_With.Image?.url} alt={props.data.Speak_With.Image?.alternativeText} />
                            </picture> */}
                            <ImageModule ImageSrc={props.data.Speak_With.Image} attr={{ alt: props.data.Speak_With?.Name }} ggfx_results={props.data.Speak_With.ggfx_results} imagename="team.Image.tail" strapi_id={props.data.Speak_With.Image?.id} />

                            {/* <ImageTransform imagesources={props.data.Speak_With.Image?.url} renderer="srcSet" imagename='team.Image.tail'
                              attr={{ alt: props.data.Speak_With.Image?.alternativeText, className: '' }}
                              imagetransformresult={processedImages} id={props.data.Speak_With.Image?.id} >

                            </ImageTransform> */}
                            {props.data.Speak_With?.Video_Url && !showVideomen &&
                            <span
                              onClick={(e) => {
                                    playVideomen(props.data.Speak_With?.Video_Url);
                                  }}
                                   className="video-btn"><i class="icon-video"></i></span>
                                }
                            </div>
                        </div>
                        <div className="person-cta">
                          <p className="int-para"><b>Interested in this role?</b></p>
                          <p className="call-us">Call us on <a className="tel-txt" href={"tel:"+props.data.Speak_With?.Phone}>{uknumberformat(props.data.Speak_With?.Phone)}</a> or <br/>
                          <a onClick={props.executeScroll} href="javascript:void(0)" className="apply-txt">Apply for this job.</a></p>

                          </div>  
                        </div>
                           {/* </ScrollAnimation>    */}
                       </Col>
                      }
                   </Row>
                   
               </Container>
           </section>
        </React.Fragment>
    )
}

export default CareersDetailsBanner;