import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Breadcrumb} from "react-bootstrap";
import "./BreadcrumbPage.scss";
import { useStaticQuery, graphql } from "gatsby";
import { CONTACT_PAGE_URL } from "@Components/common/site/constants";
export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
const BreadcrumbPageEnquiry = (props) => {
    const data = useStaticQuery(graphql`
    query{

        glstrapi {
            menus(publicationState: LIVE) {
              Alias
              URL
              External_URL
              Label
              Main_Parent {
                Alias
                URL
              }
              Sub_Parent {
                Alias
                URL
              }
              Link_Type
            }


            pages {
                Alias
                Pagename
              }

          }
    

	 

    }
  `);    
      const PageURL = data.glstrapi.menus.filter(item => item?.Alias === props?.alias).pop();


  var parent = ""
  var url = ""

  if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent == null)) {

    const Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();
    parent = Parent?.Pagename
    url = PageURL?.Main_Parent?.URL

  }

  else if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent != null)) {

    const Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();

    const Sub_Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Sub_Parent.Alias).pop();
    parent = Parent?.Pagename
    var sub_parent = Sub_Parent?.Pagename
    url = PageURL?.Main_Parent?.URL
    var sub_url = PageURL?.Sub_Parent?.URL
  }
    return (
        <React.Fragment>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn' duration={2}>
            <section className="breadcrumb-section">
                <Container>
                <Breadcrumb>
                  {/* <Breadcrumb.Item href="#" className="btn-text-link">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Expert Lettings Agent in Oxford</Breadcrumb.Item> */}
                   {props.Page !== 'Home' &&

                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    }
                    <Breadcrumb.Item href={CONTACT_PAGE_URL.alias}>Contact</Breadcrumb.Item>
                    <Breadcrumb.Item href={CONTACT_PAGE_URL.alias+'/'+props.alias}>{toTitleCase(props.alias)}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.Page}</Breadcrumb.Item>
                    {/* {PageURL?.Main_Parent !== null &&
                    <Breadcrumb.Item href={`/${url}`}>{parent}</Breadcrumb.Item>
                    }
                    {props.type === "details-page" &&
                    <Breadcrumb.Item href={`/${url}/${PageURL?.URL}`}>{PageURL?.Label}</Breadcrumb.Item>
                    }

                    {PageURL?.Sub_Parent !== null &&
                    <Breadcrumb.Item href={`/${url}/${sub_url}`}>{sub_parent}</Breadcrumb.Item>
                    }
                    <Breadcrumb.Item active>{props.Page}</Breadcrumb.Item> */}
                </Breadcrumb>
                </Container>
            </section> 
            </ScrollAnimation>
        </React.Fragment>
    )
}

export default BreadcrumbPageEnquiry;