import React, { useState, useRef } from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col } from 'react-bootstrap';
import HomeGetStart from "@Components/Home/HomeGetStart/HomeGetStart";
import AgentsTeam from "@Components/StaticPage/AgentsTeam/AgentsTeam";
import BreadcrumbPage from "@Components/Home/BreadcrumbPage/BreadcrumbPage";
import CareersDetailsBanner from "@Components/CareersDetailsBanner/CareersDetailsBanner";
import { useStaticQuery, graphql } from "gatsby";
import CareersForm from "@Components/forms/career";
import ReactMarkdown from "react-markdown"
import "./CareersDetails.scss";
import logoBlack from "../../images/logo-t.svg";

const CareersDetails = (props) => {
  const myRef = useRef(null)
  const executeScroll = () => {
      myRef.current.scrollIntoView({ behavior: 'smooth'})
  }
  const dataglobal = useStaticQuery(graphql`
  query{
  glstrapi {
    globalConfig(publicationState: LIVE) {
      Apply_Job_Content
      Apply_Job_Title
      Careers_Get_Start_Title
    }
  }
  }
  `);    
  const GET_CAREER_DETAILS = gql`
  query GetCareers($URL: String!){	
    careers(where:{URL: $URL}) {
      Job_Title
    URL
    Location
    Salary
    Tail_Image{
      url
      alternativeText
    }
    Job_Details
    Office {
      Office_Name
      Office_Address
    }
    Speak_With {
      id
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Name
      Phone
      Image {
        url
        alternativeText
      }
      Video_Url
    }
    Publish

      
  }
}
`;

  const { loading, error, data } = useQuery(GET_CAREER_DETAILS, {
    variables: { URL: props.slug }
  });
  let shareurl = '';

  if (typeof window !== "undefined") {
      shareurl = window.location.href ? window.location.href : '';
  }

  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         <div className="new-loader-anime"></div>
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="logo" />
  //       </div>
  //       <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div>
  //     </div>
  //   </section>
  // );

  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
        <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
          </div>
      </Container>
    </div>
  );

  return (
    <Layout popularSearch={"Common"} isBlack={true} className="careers-page" header={true}>
      {data?.careers.map((Page, i) => {
        return (
          <>
            <SEO title={Page ? Page.Job_Title+' jobs' : ''} description={Page ? 'Looking for '+Page.Job_Title+' job? Leading estate agency College and County offering excellent career opportunities for you send your CV today. ' : ''} />

            <Helmet
              bodyAttributes={{
                class: `templates-careers-details-template`
              }}
            />
              <CareersDetailsBanner executeScroll={executeScroll} data={Page} />
              <div ref={myRef} id="scroll_view"></div>
               <section className="carees-form form">
               <Container>
                 <Row>
                   <Col lg={6}>
               <h2>{dataglobal?.glstrapi?.globalConfig?.Apply_Job_Title}</h2>
               <ReactMarkdown source={dataglobal?.glstrapi?.globalConfig?.Apply_Job_Content} allowDangerousHtml />
               
            <CareersForm job_url={shareurl} jobtitle={Page.Job_Title} />
            
            </Col>
            </Row>
            </Container>
            </section>
            <HomeGetStart Title={dataglobal?.glstrapi?.globalConfig?.Careers_Get_Start_Title} nobanner={true} />
            <div className="breadcrumb-other-page"><BreadcrumbPage Page={Page.Job_Title} class="static" alias={'careers'} type="details-page"/></div>
          </>
        )
      })}
    </Layout>
  );
}



export default CareersDetails